import React from 'react';
import styled from 'styled-components';
import Trademark from '../../../static/trademark.svg';

const StyledHeading = styled.h1`
  position: relative;
  font-weight: ${({ theme, light }) => light && 'normal'};
  color: ${({ theme, white }) => (white ? 'white' : theme.headingColor)};
  margin-top: ${({ withTrademark }) => withTrademark && '2rem'};
  + p,
  + ul,
  + div {
    margin: 1.5rem 0 0;
  }
  &:after {
    content: ${({ withTrademark }) => withTrademark && `url(${Trademark})`};
    position: absolute;
    top: -3.5rem;
    left: ${({ centered }) => (centered ? '50%' : 0)};
    transform: ${({ centered }) => centered && 'translateX(-50%)'};
  }
`;

const Heading = ({ children, light, as, white, withTrademark, centered }) => {
  return (
    <StyledHeading
      white={white}
      as={as}
      light={light}
      withTrademark={withTrademark}
      centered={centered}
    >
      {children}
    </StyledHeading>
  );
};

export default Heading;
