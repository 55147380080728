import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 1.25rem;
  max-width: ${({ theme, big }) =>
    big ? theme.maxBigContainerWidth : theme.maxContainerWidth};
`;

export default Container;
