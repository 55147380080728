import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  margin: ${({ theme, noMargin }) =>
    noMargin ? 0 : theme.defaultMarginMobile};

  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: ${({ theme, noMargin }) => (noMargin ? 0 : theme.defaultMargin)};
  }
`;

const ComponentWrapper = ({ children, noMargin }) => {
  return <Wrapper noMargin={noMargin}>{children}</Wrapper>;
};

export default ComponentWrapper;
