import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { transparentize } from 'polished';
import Container from '../components/Container';
import Heading from '../components/typography/Heading';

const FooterWrapper = styled.footer`
  background: ${({ theme }) => theme.primaryColorDark};
  padding: 4.6rem 0 7.4rem;
  color: white;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
`;
const FooterItem = styled.div`
  width: 100%;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    &:not(:first-of-type) {
      margin: 5rem 0 0;
    }
  }

  @media (${({ theme }) => theme.respondTo.tablet}) {
    width: 25%;

    &:not(:first-of-type) {
      padding-left: 4.8rem;
    }

    &:not(:last-of-type) {
      border-right: 1px solid ${transparentize(0.8, 'white')};
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }
`;

const Footer = ({ items }) => {
  return (
    <FooterWrapper>
      <StyledContainer>
        <FooterItem>
          <Heading white as="h2">
            Plan & Plant
          </Heading>
          <ul>
            <li>Plan & Plant </li>
            <li>Jaap van Wijk </li>
            <li>Ramsweg 13d </li>
            <li>8307RM, Ens</li>
          </ul>
        </FooterItem>
        <FooterItem>
          <Heading withTrademark white as="h4">
            Menu
          </Heading>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/projecten">Projecten</Link>
            </li>
            <li>
              <Link to="/over-mij">Over mij</Link>
            </li>
          </ul>
        </FooterItem>
        <FooterItem>
          <Heading withTrademark white as="h4">
            Diensten
          </Heading>
          <ul>
            {items.map(item => (
              <li key={item.node.uid}>
                <Link to={`/diensten/${item.node.uid}`}>
                  {item.node.data.titel.text}
                </Link>
              </li>
            ))}
          </ul>
        </FooterItem>
        <FooterItem>
          <Heading withTrademark white as="h4">
            Neem contact op
          </Heading>
          <ul>
            <li>
              M. <a href="tel:0031683305091">06-83305091</a>
            </li>
            <li>
              E. <a href="mailto:info@planenplant.nl">info@planenplant.nl</a>
            </li>
            <li>NL001233756B53</li>
          </ul>
        </FooterItem>
      </StyledContainer>
    </FooterWrapper>
  );
};

export default Footer;
