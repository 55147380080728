import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 1rem 0 1rem 1.25rem;
    margin: 0 -1.25rem;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0 1.25rem 0 0;
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamilyHeading};
    color: ${({ theme }) => theme.headingColor};
    white-space: nowrap;
    font-size: 1.8rem;

    @media (${({ theme }) => theme.respondTo.tablet}) {
      padding: 0;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;

const NavItem = styled.li`
  &:not(:last-child) {
    margin: 0 4.8rem 0 0;

    @media (${({ theme }) => theme.respondTo.mobile}) {
      margin: 0 1.75rem 0 0;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }

  @media (${({ theme }) => theme.respondTo.mobile}) {
    width: 100%;
  }
`;

const Nav = ({ menu }) => {
  return (
    <StyledNav>
      <ul>
        {menu.map((item, i) => (
          <NavItem key={item.title + i}>
            <Link to={item.link}>{item.title}</Link>
          </NavItem>
        ))}
      </ul>
    </StyledNav>
  );
};

export default Nav;
