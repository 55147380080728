import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import Header from './Header';
import Footer from './Footer';
import GlobalStyle from '../styling/global';
import theme from '../styling/theme';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query PageMetaData {
        site {
          siteMetadata {
            title
          }
        }
        allPrismicDiensten {
          edges {
            node {
              data {
                titel {
                  text
                }
              }
              uid
            }
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <Header siteTitle={data.site.siteMetadata.title} menu="" />
          <main>{children}</main>
          <Footer items={data.allPrismicDiensten.edges} />
        </>
      </ThemeProvider>
    )}
  />
);

export default Layout;
