import React from 'react';
import styled from 'styled-components';

const StyledText = styled.p`
  font-size: ${({ theme }) => theme.fontSize};

  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: ${({ theme, big }) => (big ? '22px' : theme.fontSize)};
  }
`;

const Text = ({ children, big, as }) => {
  return (
    <StyledText as={as} big={big}>
      {children}
    </StyledText>
  );
};

export default Text;
