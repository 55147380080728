import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Container from './Container';
import Nav from './Nav';
import Logo from '../../static/img/logo.png';

const StyledLink = styled(Link)`
  @media (${({ theme }) => theme.respondTo.mobile}) {
    width: 7rem;
  }
`;

const StyledLogo = styled.img`
  @media (${({ theme }) => theme.respondTo.tablet}) {
    height: 6rem;
    padding: 0;
  }
`;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem 0 0;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    height: 6rem;
    padding: 0;
  }
`;

const Header = ({ siteTitle, menu }) => (
  <Container>
    <StyledHeader>
      <StyledLink to="/">
        <StyledLogo src={Logo} alt={siteTitle} />
      </StyledLink>
      <Nav
        menu={[
          {
            title: 'Home',
            link: '/',
          },
          {
            title: 'Over mij',
            link: '/over-mij/',
          },
          {
            title: 'Projecten',
            link: '/projecten/',
          },
        ]}
      />
    </StyledHeader>
  </Container>
);

export default Header;
