const theme = {
  // Colours
  primaryColor: '#A2B83B',
  primaryColorDark: '#102C18',
  lightGrey: '#F8F8F8',
  secondaryColor: '#FFFFFF',
  headingColor: '#222222',
  textColor: '#333333',

  // Fonts
  fontSize: '1.8rem',
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyHeading:
    'Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

  // Sizing
  maxContainerWidth: '1160px',
  maxBigContainerWidth: '1240px',
  defaultMargin: '0 0 12.5rem',
  defaultMarginMobile: '0 0 5rem',

  // Breakpoints
  respondTo: {
    desktopSuperBig: `min-width: ${1600 / 16}em`,
    desktopExtraBig: `min-width: ${1440 / 16}em`,
    desktopBig: `min-width: ${1280 / 16}em`,
    desktop: `min-width: ${1024 / 16}em`,
    tablet: `min-width: ${768 / 16}em`,
    mobile: `max-width: ${413.99999 / 16}em`,
  },
};

export default theme;
